import { HttpHeaders } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { OtpSmsService } from '../../../modules/profile/component/setting-otp-sms/otp-sms.service';
import { SetRef } from '../../../store/otp/otp.actions';
import { AlertService } from '../../../core/services/alert.service';
import { OtpMailService } from '../../../modules/profile/component/setting-otp/otp-mail.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomerPageService } from '../../../modules/customer-page/shared/customer-page.service';
import { MEMOS_USE_OTP } from '../../../modules/memos/model/memo';

@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss'],
})
export class OtpModalComponent implements OnInit {
  @Input() isSaved: boolean;
  @Input() otp: string | number;
  @Output() otpChange = new EventEmitter<string>();
  @Output() closeModal = new EventEmitter<void>();
  @Output() requestOtpChange = new EventEmitter<void>();
  @Input() header;
  @Input() requestForm;

  @ViewChild('otpGoogleAuthen', { static: false })
  otpGoogleAuthen: ElementRef;
  @ViewChild('otpEmail', { static: false })
  otpEmail: ElementRef;
  @ViewChild('otpPhoneNumber', { static: false })
  otpPhoneNumber: ElementRef;
  @ViewChild('otpRequestForm', { static: false })
  otpRequestForm: ElementRef;

  timeLeft = 300;
  interval;

  otpType = 'totp';
  userPhoneNumber: string;
  userEmail: string;

  refDisPlay: string;
  isLoadingOTP: boolean;
  isSendApproveLog: boolean;
  memoDetail;
  memoType = MEMOS_USE_OTP;
  sendLogSms = null;
  actionDetail = null;
  ref: string;

  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '30px',
      height: '30px',
    },
    inputClass: 'each_input',
  };

  constructor(
    public modalService: NgbModal,
    private store: Store,
    private otpSmsService: OtpSmsService,
    private otpMailService: OtpMailService,
    private alert: AlertService,
    private translate: TranslateService,
    private customerService: CustomerPageService,
  ) {}

  ngOnInit(): void {
    this.store.subscribe((s) => {
      this.memoDetail = s.memoCreationData;
      this.otpType = s.auth?.otp_type;
      this.userPhoneNumber = s.auth?.phone_number;
      this.userEmail = s.auth?.email;
    });
  }

  getHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', this.header);
  }

  openModal(
    isApproved = false,
    memoType = null,
    actionType = null,
  ): void {
    this.sendLogSms = null;
    this.memoDetail = memoType;
    this.isSendApproveLog = isApproved;
    this.actionDetail = actionType;

    if (this.otpType === 'totp') {
      this.openTOTP();
      return;
    }
    if (isApproved) {
      this.sendLogSms = {
        action_from: 'Send Sms Approve',
      };
    } else {
      this.sendLogSms = {
        action_from: 'Send Sms',
      };
    }
    this.sendOTP();
  }

  openSmsRequestForm(useModal = true, form?) {
    let header;
    if (this.header) {
      header = this.getHeader();
    }
    this.otp = null;
    const data = {
      register_type:
        form?.person_type || this.requestForm?.person_type,
      registration_id: form?.card_no || this.requestForm?.card_no,
      phone_number:
        form?.phone_number || this.requestForm?.phone_number,
      action_from: 'Send Sms Approve',
    };
    this.customerService
      .sendSmsOTPInformationCheckWithHeader(data, header)
      .subscribe(
        (res: any) => {
          this.ref = res.ref;
          this.refDisPlay = res.ref_display;
          this.store.dispatch(
            new SetRef({
              ref: res.ref,
              refDisplay: this.refDisPlay,
            }),
          );
          this.pauseTimer();
          this.timeLeft = 300;
          this.startTimer();
        },
        (error) => {
          if (error.status === 400 && error.error) {
            const firstKey = Object.keys(error.error)[0];
            try {
              const errorObj = JSON.parse(error.error[firstKey]);
              if ('detail' in errorObj) {
                this.alert.error(errorObj.detail);
              } else {
                this.alert.error(
                  this.translate.instant('LANDING.PHONE-INVALID'),
                );
              }
            } catch (e) {
              this.alert.error(error.error[firstKey]);
            }
          } else {
            this.alert.error(error.status);
          }
        },
      );
    if (useModal) {
      this.openRequestFormSms();
    }
  }

  verifyOTPRequestForm() {
    let header;
    if (this.header) {
      header = this.getHeader();
    }
    const data = {
      register_type: this.requestForm?.register_type,
      registration_id: this.requestForm?.registration_id,
      phone_number: this.requestForm?.phone_number,
      action_from: 'Send Sms Approve',
    };
    this.isLoadingOTP = true;
    this.customerService
      .verifyOTPRequestFormWithHeader(data, header)
      .subscribe(
        (res: any) => {
          this.isLoadingOTP = false;
          this.close();
          this.alert.success(
            this.translate.instant('LANDING.VERIFY-OTP'),
          );
        },
        () => {
          this.isLoadingOTP = false;
          this.close();
          this.alert.error(
            this.translate.instant('LANDING.OTP-INVALID'),
          );
        },
      );
  }

  openTOTP() {
    this.modalService.open(this.otpGoogleAuthen, {
      backdrop: 'static',
      centered: true,
    });
  }

  onOtpChange(otp: number): void {
    this.otp = otp;
  }

  sendOTP() {
    this.isLoadingOTP = true;
    let header = null;
    if (this.header) {
      header = this.getHeader();
    }
    let service = null;
    if (
      this.otpType === null &&
      this.memoType.includes(this.memoDetail)
    ) {
      service = this.otpSmsService;
    } else {
      service =
        this.otpType === 'sms'
          ? this.otpSmsService
          : this.otpMailService;
    }
    service.send(null, header).subscribe(
      (res: any) => {
        this.refDisPlay = res.ref_display;
        this.store.dispatch(
          new SetRef({
            ref: res.ref,
            refDisplay: this.refDisPlay,
          }),
        );
        this.isLoadingOTP = false;
      },
      (error) => {
        this.isLoadingOTP = false;
        if (error.status === 400 && error.error) {
          const firstKey = Object.keys(error.error)[0];
          try {
            const errorObj = JSON.parse(error.error[firstKey]);
            if ('detail' in errorObj) {
              this.alert.error(errorObj.detail);
            } else {
              this.alert.error(
                this.translate.instant('MEMOS.Error OTP Limit'),
              );
            }
          } catch (e) {
            this.alert.error(error.error[firstKey]);
          }
        } else {
          this.alert.error(error.status);
        }
      },
    );
    if (
      this.otpType === null &&
      this.memoType.includes(this.memoDetail)
    ) {
      this.openSMSOTP();
    } else {
      if (this.otpType === 'sms') {
        this.openSMSOTP();
      } else {
        this.openEmailOTP();
      }
    }
  }

  openRequestFormSms() {
    this.modalService.open(this.otpRequestForm, {
      backdrop: 'static',
      centered: true,
    });
  }

  openSMSOTP() {
    this.modalService.open(this.otpPhoneNumber, {
      backdrop: 'static',
      centered: true,
    });
    this.startTimer();
  }

  openEmailOTP() {
    this.modalService.open(this.otpEmail, {
      backdrop: 'static',
      centered: true,
    });
    this.startTimer();
  }

  startTimer(): void {
    this.pauseTimer();
    this.timeLeft = 300;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.pauseTimer();
      }
    }, 1000);
  }

  pauseTimer(): void {
    clearInterval(this.interval);
  }

  resend(): void {
    this.isLoadingOTP = true;
    let header = null;
    if (this.header) {
      header = this.getHeader();
    }
    let service = null;
    if (
      this.otpType === null &&
      this.memoType.includes(this.memoDetail)
    ) {
      service = this.otpSmsService;
    } else {
      service =
        this.otpType === 'sms'
          ? this.otpSmsService
          : this.otpMailService;
    }
    service.send(this.sendLogSms, header).subscribe(
      (res: any) => {
        this.refDisPlay = res.ref_display;
        this.store.dispatch(
          new SetRef({
            ref: res.ref,
            refDisplay: this.refDisPlay,
          }),
        );
        this.isLoadingOTP = false;
        this.startTimer();
        this.otp = null;
      },
      (error) => {
        this.isLoadingOTP = false;
        if (error.status === 400 && error.error) {
          const firstKey = Object.keys(error.error)[0];
          try {
            const errorObj = JSON.parse(error.error[firstKey]);
            if ('detail' in errorObj) {
              this.alert.error(errorObj.detail);
            } else {
              this.alert.error(
                this.translate.instant('MEMOS.Error OTP Limit'),
              );
            }
          } catch (e) {
            this.alert.error(error.error[firstKey]);
          }
        } else {
          this.alert.error(error.status);
        }
      },
    );
  }

  close(): void {
    this.modalService.dismissAll();
    this.otp = null;
    this.sendLogSms = null;
    this.memoDetail = null;
    this.closeModal.emit();
  }

  get invalidOTP(): boolean {
    return !this.otp || String(this.otp).length < 6;
  }

  get hiddenNumber(): string {
    return (
      this.userPhoneNumber.substring(0, 3) +
      '****' +
      this.userPhoneNumber.substring(7)
    );
  }
  get hiddenNumberRequestForm(): string {
    return (
      this.requestForm?.phone_number.substring(0, 3) +
      '****' +
      this.requestForm?.phone_number.substring(7)
    );
  }

  get hiddenEmail(): string {
    if (!this.userEmail) {
      return '';
    }
    const email_length = this.userEmail.split('@')[0].length;
    const email_slice_index = (email_length * 20) / 100;
    return (
      this.userEmail.slice(0, email_slice_index) +
      '****@' +
      this.userEmail.split('@')[1]
    );
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
