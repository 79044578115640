import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../core/http/api.service';
import { DropdownItem } from '../models/common.model';
import { API_URL } from './api.constant';
import { Store } from '@ngxs/store';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import {
  ResponseBuilding,
  ResponseFloor,
  ResponseOffice,
  ResponseProperty,
} from '../../modules/memos/model/memo.model';
import { MemoType } from '../../modules/memos/model/memo';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  constructor(
    private http: HttpClient,
    private httpClient: ApiService,
    private store: Store,
  ) {}

  getDropdown(
    params,
  ): Observable<{ [dropdownName: string]: DropdownItem[] }> {
    return this.httpClient.get<{
      [dropdownName: string]: DropdownItem[];
    }>(API_URL.dropdown, params);
  }

  getDropdownWithHeader(header, params) {
    return this.http.get(environment.baseUrl + API_URL.dropdown, {
      params,
      headers: header,
    });
  }

  getDropdownPublic(
    params,
  ): Observable<{ [dropdownName: string]: DropdownItem[] }> {
    return this.httpClient.get<{
      [dropdownName: string]: DropdownItem[];
    }>(API_URL.public_dropdown, params);
  }

  memoTypeItem() {
    return [
      {
        label: 'External',
        value: 'external',
        context: {
          display_name: 'แบบขออนุมัติภายนอก',
          display_name_en: 'External',
        },
      },
      {
        label: 'Internal',
        value: 'internal',
        context: {
          display_name: 'แบบขออนุมัติภายใน',
          display_name_en: 'Internal',
        },
      },
      {
        label: 'Purchase Request',
        value: 'purchase_request',
        context: {
          display_name: 'แบบขออนุมัติการซื้อ',
          display_name_en: 'Purchase Request',
        },
      },
      {
        label: 'Reservation Contract',
        value: 'reservation',
        context: {
          display_name: 'ใบจอง',
          display_name_en: 'Reservation Contract',
        },
      },
      {
        label: 'Contract',
        value: 'agreement_contract',
        context: {
          display_name: 'สัญญา',
          display_name_en: 'Contract',
        },
      },
      {
        label: 'Transfer Room Document',
        value: 'transfer_document',
        context: {
          display_name: 'ใบส่งมอบ',
          display_name_en: 'Transfer Room Document',
        },
      },
      {
        label: 'Receive Room Document',
        value: 'receive_document',
        context: {
          display_name: 'ใบรับมอบ',
          display_name_en: 'Receive Room Document',
        },
      },
      {
        label: 'Rent Appliance',
        value: 'rent_appliance',
        context: {
          display_name: 'ใบขอเช่าอุปกรณ์เพิ่ม',
          display_name_en: 'Rent Appliance',
        },
      },
      {
        label: 'Parking Contract',
        value: 'contract_car_park',
        context: {
          display_name: 'สัญญาที่จอดรถ',
          display_name_en: 'Parking Contract',
        },
      },
      {
        label: 'LOI (จอง)',
        value: 'loi',
        context: {
          display_name: 'LOI (จอง)',
          display_name_en: 'LOI (Reservation)',
        },
      },
      {
        label: 'สัญญาโชติบิซ',
        value: 'contract_chod_biz',
        context: {
          display_name: 'สัญญาโชติบิซ',
          display_name_en: 'Chodbiz Contract',
        },
      },
      {
        label: 'ใบรับมอบโชติบิซ',
        value: MemoType.ReceiveChodBiz,
        context: {
          display_name: 'ใบรับมอบโชติบิซ',
          display_name_en: 'Receive Document Chodbiz',
        },
      },
      {
        label: 'Upload',
        value: 'upload',
        context: {
          display_name: 'เอกสารอัปโหลด',
          display_name_en: 'Upload',
        },
      },
    ];
  }

  getNewOfficeItems(
    floorControlName: string,
  ): Observable<ResponseOffice[]> {
    return this.getDropdownItem('floor', 'office', floorControlName);
  }

  getNewProperty(
    locationControlName: string,
    memoType?: string,
  ): Observable<ResponseProperty[]> {
    return this.getDropdownItem(
      'location',
      'property',
      locationControlName,
      memoType,
    );
  }

  getNewBuilding(
    propertyControlName: string,
  ): Observable<ResponseBuilding[]> {
    return this.getDropdownItem(
      'property',
      'building',
      propertyControlName,
    );
  }

  getNewFloor(
    buildingControlName: string,
  ): Observable<ResponseFloor[]> {
    return this.getDropdownItem(
      'building',
      'floor',
      buildingControlName,
    );
  }

  private getDropdownItem(
    paramsKeys: string,
    type: string,
    mapValueKeys?: string,
    memoType?: string,
  ): Observable<any[]> {
    const memo = this.store.selectSnapshot(
      (state) => state.memoCreationData,
    );
    return this.store
      .select((state) => state.memoCreationData?.[mapValueKeys])
      .pipe(
        take(1),
        filter((data) => !!data),
        switchMap((data) => {
          const params = {
            [paramsKeys]: data,
            type: type,
            ...(memoType ? { memo_type: memoType } : {}),
          };
          return this.getDropdown(params);
        }),
        map((res: { [p: string]: any }) => {
          return type === 'office' &&
            memo?.request_type === 'change_office'
            ? res[type].filter((item) =>
                ['available', 'wait_to_move_out'].includes(
                  item.context.status,
                ),
              )
            : res[type];
        }),
        catchError((error) => {
          console.error(error);
          return throwError(error);
        }),
      );
  }
  requestDropdown(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getDropdown(params).subscribe({
        next: (response) => {
          resolve(response[params?.type]);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }
}

export interface DropdownResponse {
  [type: string]: {
    label: any;
    value: any;
    context?: any;
  }[];
}
