<!-- *ngIf="person && !onEdit" -->
<div *ngIf="!onEdit && (individual||company)">
  <div class="row no-gutters mt-2">
    <div class="col-7 d-flex">
      <fa-icon [icon]="chevronLeft"
          [size]="2"
          class="pointer mt-2 mr-3 hidden-arrow"
          (click)="onClickBack()"></fa-icon>
      <h3 class="mb-3 detail"
          *ngIf="typeCheckDetail === 'individual'">
        {{ individual.first_name || 'ไม่ระบุชื่อ' }} <span class="display-sm">
          {{ individual.last_name }}
        </span>
      </h3>
      <h3 class="mb-3 detail"
          *ngIf="typeCheckDetail === 'company'">
        {{ company.business_name || 'ไม่ระบุชื่อ' }}
      </h3>
    </div>


    <div class="col-5 text-right justify-content-end"
        *ngIf="company && company.status_for_approval_by_admin === 'approve'
        && !role.includes('Viewer')">
      <button class="btn p-btn"
          type="button"
          appSetTheme
          (click)="editProfile()">
        {{"MEMOS.EDIT"|translate}}
      </button>
    </div>
    <div class="col-5 text-right justify-content-end"
        *ngIf="company && company.status_for_approval_by_admin === 'reject'
      && !role.includes('Viewer')">
      <button class="btn p-btn"
          type="button"
          appSetTheme
          (click)="recoverCompany(company.id)">
        {{"MANAGE-CUSTOMER.RECOVERY"|translate}}
      </button>
    </div>
    <div class="col-5 text-right justify-content-end row"
        *ngIf="company && company.status_for_approval_by_admin === 'pending'
      && !role.includes('Viewer')">
      <button class="btn btn-danger col p-2 ml-3"
          (click)="openModal(rejectModal)">
        <svg-icon class="my-auto"
            src="assets/images/icons/icon_discard.svg"
            [svgStyle]="{ 'height.px': 15, 'width.px': 15 }"></svg-icon>
        <span>
          {{'MANAGE-CUSTOMER.REJECT'| translate}}
        </span>
      </button>
      <button class="btn btn-success col p-2 ml-3"
          (click)="approvalCompany(company)">
        <svg-icon src="assets/images/icons/icon_approve.svg"
            [svgStyle]="{ 'height.px': 15, 'width.px': 15 }"></svg-icon>
        <span>
          {{ 'APPROVAL.APPROVED' | translate }}
        </span>
      </button>
    </div>

    <div class="col-5 text-right justify-content-end"
        *ngIf="individual && individual.status_for_approval_by_admin ===
      'approve' && !role.includes('Viewer')">
      <button class="btn p-btn"
          type="button"
          appSetTheme
          (click)="editProfile()">
        {{"MEMOS.EDIT"|translate}}
      </button>
    </div>
    <div class="col-5 text-right justify-content-end"
        *ngIf="individual && individual.status_for_approval_by_admin ===
      'reject' && !role.includes('Viewer')">
      <button class="btn p-btn"
          type="button"
          appSetTheme
          (click)="recoverIndividual(individual.id)">
        {{"MANAGE-CUSTOMER.RECOVERY"|translate}}
      </button>
    </div>
    <div class="col-5 text-right justify-content-end row"
        *ngIf="individual && individual.status_for_approval_by_admin ===
      'pending' && !role.includes('Viewer')">
      <button class="btn btn-danger col p-2 ml-3"
          (click)="openModal(rejectModal)">
        <svg-icon class="my-auto"
            src="assets/images/icons/icon_discard.svg"
            [svgStyle]="{ 'height.px': 15, 'width.px': 15 }"></svg-icon>
        <span>
          {{'MANAGE-CUSTOMER.REJECT'| translate}}
        </span>
      </button>
      <button class="btn btn-success col p-2 ml-3"
          (click)="approvalIndividual(individual)">
        <svg-icon src="assets/images/icons/icon_approve.svg"
            [svgStyle]="{ 'height.px': 15, 'width.px': 15 }"></svg-icon>
        <span>
          {{ 'APPROVAL.APPROVED' | translate }}
        </span>
      </button>
    </div>
  </div>

  <div class="row no-gutters">
    <div class="col text-center"
        *ngIf="person !== null">
      <img class="profile-pic"
          [src]="(person?.photo | secure | async) || 'assets/images/person.png'">
    </div>
    <div class="col text-center"
        *ngIf="person === null">
      <img class="profile-pic"
          [src]="'assets/images/person.png'">
    </div>
    <!-- tab select -->
    <div class="selections-tab-container col-12 pt-lg-3 pt-md-3 pr-lg-3 pr-md-3
      pl-lg-3 pl-md-3">
      <div class="tab w-100">
        <span *ngIf="typeCheckDetail === 'individual'"
            class="item"
            [class.active]="activeSelectionTabItem === selectionTabItems[0]"
            (click)="confirmSwitchTab(selectionTabItems[0])">
          <span>{{selectionTabItems[0].translateLabel|translate}}</span>
        </span>
        <span *ngIf="typeCheckDetail === 'company'"
            class="item"
            [class.active]="activeSelectionTabItem === selectionTabItems[1]"
            (click)="confirmSwitchTab(selectionTabItems[1])">
          <span>{{selectionTabItems[1].translateLabel|translate}}</span>
        </span>
        <span class="item"
            [class.active]="activeSelectionTabItem === selectionTabItems[2]"
            (click)="confirmSwitchTab(selectionTabItems[2])">
          <span>{{selectionTabItems[2].translateLabel|translate}}</span>
        </span>
        <span class="item"
            [class.active]="activeSelectionTabItem === selectionTabItems[3]"
            (click)="confirmSwitchTab(selectionTabItems[3])">
          <span>{{selectionTabItems[3].translateLabel|translate}}</span>
        </span>
        <span class="item w-100">&nbsp;</span>
      </div>
    </div>

    <div [ngSwitch]="activeSelectionTabItem.name"
        class="col-12 pb-lg-4 pb-md-4 pr-lg-4 pr-md-4 pl-lg-4 pl-md-4">
      <ng-container *ngSwitchCase="selectionTabItems[0].name">
        <table class="table mt-0 box-detail table-layout-fixed table-td-wrap">
          <tbody *ngIf="typeCheckDetail === 'individual'">
            <!-- user -->
            <tr *ngIf="individual.status_for_approval_by_admin !== 'reject'
              && individual.person">
              <td width="50%">{{'PROFILE.Status' | translate}}</td>
              <td class="text-right">
                <div class="mt-1 enable-status"
                    [class.disabled]="!person?.user.is_active">
                  <span class="dot mr-1"></span>
                  {{person?.user.is_active === false ?
                  ('PROFILE.Disable'|translate):('PROFILE.Enable'|translate)
                  }}
                </div>
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.USER-TYPE" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ 'MANAGE-CUSTOMER.INDIVIDUAL'|translate }}
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.CARD-NO-PASSPORT" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ individual.card_no | citizen }}
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.NAME-PREFIX" | translate}}</td>
              <td width="50%"
                  class="text-right">
                <label *ngIf="individual.name_prefix === 'Mr.'">
                  {{'MANAGE-CUSTOMER.MR'|translate}}
                </label>
                <label *ngIf="individual.name_prefix === 'Ms.'">
                  {{'MANAGE-CUSTOMER.MISS'|translate}}
                </label>
                <label *ngIf="individual.name_prefix === 'Mrs.'">
                  {{'MANAGE-CUSTOMER.MRS'|translate}}
                </label>
                <label *ngIf="individual.other_prefix">
                  {{individual.other_prefix}}
                </label>
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.NAME" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ individual.first_name }}
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.LAST-NAME" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ individual.last_name }}
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.BIRTHDAY" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{formatDateFromLanguage(individual.birth_date)}}
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.ADDRESS-ID" | translate}}</td>
              <td width="50%"
                  class="text-right"
                  *ngIf="individual.sub_district_display">
                <label>
                  {{"MANAGE-CUSTOMER.HOUSE-NO" | translate}}&nbsp;{{
                  individual.house_no }}&nbsp;
                </label>
                <label *ngIf="individual.village_no">
                  {{"MANAGE-CUSTOMER.VILLAGE-NO" | translate}}&nbsp;{{
                  individual.village_no }}&nbsp;
                </label>
                <label *ngIf="individual.road">
                  {{"MANAGE-CUSTOMER.ROAD" | translate}}&nbsp;{{ individual.road
                  }}&nbsp;
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.SUB-DISTRICT" | translate}}&nbsp;
                  {{ currentLang === 'en'?
                  individual?.sub_district_display?.value_eng:
                  individual?.sub_district_display?.value_thai }}&nbsp;
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.DISTRICT" | translate}}&nbsp;
                  {{ currentLang === 'en'?
                  individual?.district_display?.value_eng:
                  individual?.district_display?.value_thai}}&nbsp;
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.PROVINCE" | translate}}&nbsp;
                  {{ currentLang === 'en'?
                  individual?.province_display?.value_eng:
                  individual?.province_display?.value_thai }}&nbsp;
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.POSTRAL-CODE" | translate}}&nbsp;{{
                  individual?.postal_code_display?.number }}
                </label>
              </td>
              <td width="50%"
                  class="text-right"
                  *ngIf="!individual.sub_district_display">
                -
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.ADDRESS-CONTRACT" | translate}}</td>
              <td width="50%"
                  class="text-right"
                  *ngIf="individual?.contact_sub_district_display">
                <label>
                  {{"MANAGE-CUSTOMER.HOUSE-NO" | translate}}&nbsp;{{
                  individual?.contact_house_no }}&nbsp;
                </label>
                <label *ngIf="individual?.contact_village_no">
                  {{"MANAGE-CUSTOMER.VILLAGE-NO" | translate}}&nbsp;{{
                  individual?.contact_village_no }}&nbsp;
                </label>
                <label *ngIf="individual?.contact_road">
                  {{"MANAGE-CUSTOMER.ROAD" | translate}}&nbsp;{{
                  individual?.contact_road }}
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.SUB-DISTRICT" | translate}}&nbsp;
                  {{ currentLang === 'en'?
                  individual?.contact_sub_district_display?.value_eng:
                  individual?.contact_sub_district_display?.value_thai }}&nbsp;
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.DISTRICT" | translate}}&nbsp;
                  {{ currentLang === 'en'?
                  individual?.contact_district_display?.value_eng:
                  individual?.contact_district_display?.value_thai}}&nbsp;
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.PROVINCE" | translate}}&nbsp;
                  {{ currentLang === 'en'?
                  individual?.contact_province_display?.value_eng:
                  individual?.contact_province_display?.value_thai }}&nbsp;
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.POSTRAL-CODE" | translate}}&nbsp;{{
                  individual?.contact_postal_code_display?.number }}
                </label>
              </td>
              <td width="50%"
                  class="text-right"
                  *ngIf="!individual?.contact_sub_district_display">
                -
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.MAIN-EMAIL" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ individual?.email }}
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.SECONDARY-EMAIL" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ individual?.secondary_email || '-'}}
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.NUMBER-PHONE" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ individual?.phone_number | phone}}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{"MANAGE-CUSTOMER.LOCATION" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ currentLang ==='en' ?
                (individual?.location_display?.name_en || '-'):
                (individual?.location_display?.name_th || '-') }}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{'MANAGE-CUSTOMER.TYPE-PROPERTY' | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ currentLang ==='en' ?
                (individual?.property_display?.name_en || '-'):
                (individual?.property_display?.name_th || '-') }}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{'MASTER-DATA.BUILDING' | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ individual?.building_display?.name || '-' }}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{'LANDING.The room number you wish to reserve' | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ individual?.room_reserve || '-' }}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{'LANDING.Number of Occupants' | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ currentLang ==='en' ?
                (individual?.number_of_occupants_display?.value_en || '-'):
                (individual?.number_of_occupants_display?.value_th || '-') }}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{'LANDING.Duration of the contract' | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ individual?.duration_contract || '-' }}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{'LANDING.Additional details' | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ individual?.additional_details || '-' }}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{'MANAGE-CUSTOMER.CHANNEL' | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ individual?.channel_display || '-' }}
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <div *ngSwitchCase="selectionTabItems[1].name"
          class="scrollable">
        <table class="table mt-0 box-detail table-layout-fixed table-td-wrap">
          <tbody *ngIf="typeCheckDetail === 'company'">
            <!-- company -->
            <tr *ngIf="company.status_for_approval_by_admin !== 'reject'
              && company.person">
              <td width="50%">{{'PROFILE.Status' | translate}}</td>
              <td class="text-right">
                <div class="mt-1 enable-status"
                    [class.disabled]="!person?.user.is_active">
                  <span class="dot mr-1"></span>
                  {{person?.user.is_active === false ?
                  ('PROFILE.Disable'|translate):('PROFILE.Enable'|translate)
                  }}
                </div>
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.COMPANY-TYPE" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ 'MANAGE-CUSTOMER.COMPANY'|translate }}
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.TAX-ID" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ company.registration_id | company }}
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.LEGAL-ENTITY-TYPE" |
                translate}}</td>
              <td width="50%"
                  class="text-right">
                <label *ngIf="company.company_type === 'limited_company'">
                  {{"MANAGE-CUSTOMER.COMPANY" | translate}}
                </label>
                <label *ngIf="company.company_type ===
                  'public_limited_company'">
                  {{"MANAGE-CUSTOMER.LIMITED-COMPANY" | translate}}
                </label>
                <label *ngIf="company.company_type === 'limited_partnership'">
                  {{"MANAGE-CUSTOMER.PARTNERSHIP" | translate}}
                </label>
                <label *ngIf="company.other_company_type">
                  {{ company.other_company_type }}
                </label>
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.NAME" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ company.business_name }}
              </td>
            </tr>
            <tr *ngFor="let comittee of company.committees; let i = index">
              <td width="50%">{{"MANAGE-CUSTOMER.COMMITTEE" | translate}}</td>
              <td width="50%"
                  class="text-right">
                <label *ngIf="comittee.prefix === 'Mr.'">
                  {{'MANAGE-CUSTOMER.MR'|translate}}
                </label>
                <label *ngIf="comittee.prefix === 'Ms.'">
                  {{'MANAGE-CUSTOMER.MISS'|translate}}
                </label>
                <label *ngIf="comittee.prefix === 'Mrs.'">
                  {{'MANAGE-CUSTOMER.MRS'|translate}}
                </label>
                <label *ngIf="comittee.prefix_other">
                  {{comittee.prefix_other}}&nbsp;
                </label>
                {{ comittee.first_name }}&nbsp;{{
                comittee.last_name }}
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.CONTACT" | translate}}</td>
              <td width="50%"
                  class="text-right">
                <label *ngIf="company.committee_prefix === 'Mr.'">
                  {{'MANAGE-CUSTOMER.MR'|translate}}
                </label>
                <label *ngIf="company.committee_prefix === 'Ms.'">
                  {{'MANAGE-CUSTOMER.MISS'|translate}}
                </label>
                <label *ngIf="company.committee_prefix === 'Mrs.'">
                  {{'MANAGE-CUSTOMER.MRS'|translate}}
                </label>
                <label *ngIf="company.committee_prefix_other">
                  {{company.contact_prefix_other}}&nbsp;
                </label>
                {{ company.contact_first_name }}&nbsp;{{
                company.contact_last_name }}
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.USE-ADDRESS-COMPANY" |
                translate}}</td>
              <td width="50%"
                  class="text-right"
                  *ngIf="company.sub_district_display">
                <label>
                  {{"MANAGE-CUSTOMER.HOUSE-NO" | translate}}&nbsp;{{
                  company.house_no }}&nbsp;
                </label>
                <label *ngIf="company.village_no">
                  {{"MANAGE-CUSTOMER.VILLAGE-NO" | translate}}&nbsp;{{
                  company.village_no }}&nbsp;
                </label>
                <label *ngIf="company.road">
                  {{"MANAGE-CUSTOMER.ROAD" | translate}}&nbsp;{{ company.road
                  }}&nbsp;
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.SUB-DISTRICT" | translate}}&nbsp;
                  {{ currentLang === 'en'?
                  company?.sub_district_display?.value_eng:
                  company?.sub_district_display?.value_thai }}&nbsp;
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.DISTRICT" | translate}}&nbsp;
                  {{ currentLang === 'en'? company?.district_display?.value_eng:
                  company?.district_display?.value_thai}}&nbsp;
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.PROVINCE" | translate}}&nbsp;
                  {{ currentLang === 'en'? company?.province_display?.value_eng:
                  company?.province_display?.value_thai }}&nbsp;
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.POSTRAL-CODE" | translate}}&nbsp;{{
                  company?.postal_code_display?.number }}
                </label>
              </td>
              <td width="50%"
                  class="text-right"
                  *ngIf="!company.sub_district_display">
                -
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.ADDRESS-CONTRACT" | translate}}</td>
              <td width="50%"
                  class="text-right"
                  *ngIf="company?.contact_sub_district_display">
                <label>
                  {{"MANAGE-CUSTOMER.HOUSE-NO" | translate}}&nbsp;{{
                  company?.contact_house_no }}&nbsp;
                </label>
                <label *ngIf="company.contact_village_no">
                  {{"MANAGE-CUSTOMER.VILLAGE-NO" | translate}}&nbsp;{{
                  company?.contact_village_no }}&nbsp;
                </label>
                <label *ngIf="company?.contact_road">
                  {{"MANAGE-CUSTOMER.ROAD" | translate}}&nbsp;{{
                  company?.contact_road }}
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.SUB-DISTRICT" | translate}}&nbsp;
                  {{ currentLang === 'en'?
                  company?.contact_sub_district_display?.value_eng:
                  company?.contact_sub_district_display?.value_thai }}&nbsp;
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.DISTRICT" | translate}}&nbsp;
                  {{ currentLang === 'en'?
                  company?.contact_district_display?.value_eng:
                  company?.contact_district_display?.value_thai}}&nbsp;
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.PROVINCE" | translate}}&nbsp;
                  {{ currentLang === 'en'?
                  company?.contact_province_display?.value_eng:
                  company?.contact_province_display?.value_thai }}&nbsp;
                </label>
                <label>
                  {{"MANAGE-CUSTOMER.POSTRAL-CODE" | translate}}&nbsp;{{
                  company?.contact_postal_code_display?.number }}
                </label>
              </td>
              <td width="50%"
                  class="text-right"
                  *ngIf="!company?.contact_sub_district_display">
                -
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.MAIN-EMAIL" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{company.email}}
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.SECONDARY-EMAIL" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ company.secondary_email || '-'}}
              </td>
            </tr>
            <tr>
              <td width="50%">{{"MANAGE-CUSTOMER.NUMBER-PHONE" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ company.phone_number | phone}}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{"MANAGE-CUSTOMER.LOCATION" | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ currentLang === 'en' ?
                (company?.location_display?.name_en || '-'):
                (company?.location_display?.name_th) || '-' }}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{'MANAGE-CUSTOMER.TYPE-PROPERTY' | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ currentLang ==='en' ?
                (company?.property_display?.name_en || '-'):
                (company?.property_display?.name_th || '-') }}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{'MASTER-DATA.BUILDING' | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ company?.building_display?.name || '-' }}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{'LANDING.The room number you wish to reserve' | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ company?.room_reserve || '-' }}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{'LANDING.Number of Occupants' | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ currentLang ==='en' ?
                (company.number_of_occupants_display?.value_en || '-'):
                (company.number_of_occupants_display?.value_th || '-') }}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{'LANDING.Duration of the contract' | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ company.duration_contract || '-' }}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{'LANDING.Additional details' | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ company.additional_details || '-' }}
              </td>
            </tr>
            <tr>
              <td width="50%"> {{'MANAGE-CUSTOMER.CHANNEL' | translate}}</td>
              <td width="50%"
                  class="text-right">
                {{ company.channel_display || '-' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngSwitchCase="selectionTabItems[2].name"
          class="scrollable">
        <table class="table mt-0 box-detail">
          <thead>
            <tr>
              <th class="text-center">
                {{"MANAGE-CUSTOMER.NO" | translate}}
              </th>
              <th class="text-center">
                {{"MANAGE-CUSTOMER.USER" | translate}}
              </th>
              <th class="text-center">
                {{"MANAGE-CUSTOMER.DATE-TIME" | translate}}
              </th>
              <th class="text-center">
                {{"MANAGE-CUSTOMER.ACTION" | translate}}
              </th>
              <th class="text-center">
                {{ "APPROVAL.REASON"| translate }}
              </th>
            </tr>
          </thead>
          <tbody *ngIf="typeCheckDetail === 'individual'">
            <tr *ngFor="let operation_log of operation_logs;let index= index">
              <td class="text-center hide-detail">
                {{itemsPerPage * (currentPage - 1) + index + 1}}
              </td>
              <td class="text-center">
                {{operation_log?.actor?.full_name}}
              </td>
              <td class="text-center">
                {{operation_log.created_at |date: 'dd/MM/yyyy HH:mm'}}
              </td>
              <td class="text-left">
                {{ operation_log.action || '-'}}
              </td>
              <td class="text-center">
                {{ operation_log.context.reason || '-'}}
              </td>
            </tr>
            <tr *ngIf="!operation_logs.length">
              <td colspan="5"
                  class="text-center">
                {{"MEMOS.NO-DATA" | translate}}
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="typeCheckDetail === 'company'">
            <tr *ngFor="let operation_log of operation_logs;let index= index">
              <td class="text-center hide-detail">
                {{itemsPerPage * (currentPage - 1) + index + 1}}
              </td>
              <td class="text-center">
                {{ company.business_name }}
              </td>
              <td class="text-center">
                {{operation_log.created_at |date: 'dd/MM/yyyy HH:mm'}}
              </td>
              <td class="text-left">
                {{ operation_log.action || '-'}}
              </td>
              <td class="text-center">
                {{ operation_log.context.reason || '-'}}
              </td>
            </tr>
            <tr *ngIf="!operation_logs.length">
              <td colspan="5"
                  class="text-center">
                {{"MEMOS.NO-DATA" | translate}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngSwitchCase="selectionTabItems[3].name"
          class="scrollable">
        <table class="table mt-0 box-detail">
          <thead>
            <tr class="table-header"
                appSetTheme
                type="headerTable">
              <th class="text-center">
                {{"MANAGE-CUSTOMER.NO" | translate}}
              </th>
              <th class="text-center">
                {{"MASTER-DATA.BUILDING" | translate}}
              </th>
              <th class="text-center">
                {{"MASTER-DATA.FLOOR" | translate}}
              </th>
              <th class="text-center">
                {{"MASTER-DATA.OFFICE" | translate}}
              </th>
              <th class="text-center">
                {{ "MANAGE-CUSTOMER.RENTAL-PERIOD"| translate }}
              </th>
              <th class="text-center">
                {{ "MANAGE-CUSTOMER.MEMO-DETAIL"| translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let log of person?.rental_log; let index= index;">
              <td class="text-center hide-detail">
                {{itemsPerPage * (currentPage - 1) + index + 1}}
              </td>
              <td class="text-center">
                {{ log.building || '-' }}
              </td>
              <td class="text-center">
                {{ log.floor || '-' }}
              </td>
              <td class="text-center">
                {{ log.office || '-' }}
              </td>
              <td class="text-center">
                {{ log.date_range || '-' }}
              </td>
              <td class="text-center">
                <span *ngFor="let item of log?.memo_details; let i= index;">
                  <a class="san-serif memo-name text-primary"
                      target="_blank"
                      [routerLink]="[memoPreviewPath, item.memo_id]">
                    {{item.memo_number}}
                  </a>
                  {{ i !== log?.memo_details.length - 1 ? ',' : '' }}
                </span>
              </td>
            </tr>
            <tr *ngIf="!person?.rental_log.length">
              <td colspan="7"
                  class="text-center">
                {{"MEMOS.NO-DATA" | translate}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- tab delete and enable and disable -->
      <ng-container *appCheckPermission="['Admin','Master Admin']">
        <div *ngIf="individual">
          <div class="menu-bar mt-4"
              *appCheckPermission="['Admin','Master Admin', 'General']">
            <button class="btn btn-outline-primary p-btn"
                *ngIf="individual.status_for_approval_by_admin === 'approve'
              && !person?.user.is_active"
                (click)="openUpdate('true', 'individual', accessible)">
              {{"CREATE-USER.ENABLE" | translate}}
            </button>
            <button class="btn btn-outline-primary p-btn"
                *ngIf="individual.status_for_approval_by_admin === 'approve'
              && person?.user.is_active"
                (click)="openUpdate('false', 'individual', accessible)"
                [ngbTooltip]="toolTipDisable"
                container="body"
                placement="top">
              {{"CREATE-USER.DISABLE" | translate}}
            </button>
            <app-delete-confirmation-modal [type]="'line-out'"
                [data]="individual"
                (submitted)="deleteIndividual(individual)">
            </app-delete-confirmation-modal>
            <button *ngIf="isRepeatEmail"
                class="btn btn-outline-primary ml-2 p-btn"
                type="button"
                (click)="editProfile()">
              {{"MEMOS.EDIT"|translate}}
            </button>
          </div>
        </div>
        <div *ngIf="company">
          <div class="menu-bar mt-4"
              *appCheckPermission="['Admin','Master Admin', 'General']">
            <button class="btn btn-outline-primary p-btn"
                *ngIf="company.status_for_approval_by_admin === 'approve'
              && !person?.user.is_active"
                (click)="openUpdate('true', 'company', accessible)">
              {{"CREATE-USER.ENABLE" | translate}}
            </button>
            <button class="btn btn-outline-primary p-btn"
                *ngIf="company.status_for_approval_by_admin === 'approve'
              && person?.user.is_active"
                (click)="openUpdate('false', 'company', accessible)"
                [ngbTooltip]="toolTipDisable"
                container="body"
                placement="top">
              {{"CREATE-USER.DISABLE" | translate}}
            </button>
            <app-delete-confirmation-modal [type]="'line-out'"
                [data]="company"
                (submitted)="deleteCompany(company)">
            </app-delete-confirmation-modal>
            <button *ngIf="isRepeatEmail"
                class="btn btn-outline-primary ml-2 p-btn"
                type="button"
                (click)="editProfile()">
              {{"MEMOS.EDIT"|translate}}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="w-100 h-100 display-sm-none"
    *ngIf="!(individual||company) && showResult">
  <div class="no-data">
    <h5>{{'APPROVAL.CONTENT-ONE'| translate}}</h5>
  </div>
</div>

<!-- template for enable or disable person or company -->
<ng-template #accessible
    let-c="close"
    let-d="dismiss">
  <div class="modal-header">
    <h5 class="m-0"
        *ngIf="eventStatus === 'true'">{{"CREATE-USER.ENABLE" | translate}}</h5>
    <h5 class="m-0"
        *ngIf="eventStatus === 'false'">{{"CREATE-USER.DISABLE" | translate}}</h5>
  </div>
  <div class="modal-body text-center">
    <div class="f-16">
      {{ (eventStatus === 'true'
      ? "CREATE-USER.CONFIRM-ENABLE"
      : "CREATE-USER.CONFIRM-DISABLE") | translate }}
    </div>
    <div class="mt-3 text-thin"
        *ngIf="eventStatus === 'false'">
      {{'CREATE-USER.if you disable,user will be deleted temporarily'|translate}}
    </div>
  </div>
  <div class="modal-footer text-right">
    <button class="btn btn-primary"
        *ngIf="company"
        (click)="updateAccessible(eventStatus, 'company')">{{"CREATE-USER.CONFIRM"
      |
      translate}}</button>
    <button class="btn btn-primary"
        *ngIf="individual"
        (click)="updateAccessible(eventStatus, 'individual')">{{"CREATE-USER.CONFIRM"
      |
      translate}}</button>
    <button class="btn btn-outline-primary"
        (click)="closeModal()">{{"CREATE-USER.CANCEL" | translate}}</button>
  </div>
</ng-template>

<!-- edit data -->
<app-customer-edit *ngIf="(individual||company) && onEdit && showResult"
    [(detail)]="individual||company"
    [(person)]="person"
    (back)="onEdit= false; backToDetail()">
</app-customer-edit>

<!-- reason reject -->
<ng-template #rejectModal
    let-c="close"
    let-d="dismiss"
    class="modal-dialog"
    let-modal>
  <div class="modal-header">
    <h5 class="modal-title flex-grow-1 text-center">
      {{ 'MANAGE-CUSTOMER.CONFIRM-REJECT' | translate }}
    </h5>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body py-4 f-18">
    <span>{{ 'APPROVAL.REASON' | translate }}</span>
    <div class="form-group mt-2">
      <textarea class="form-control"
          rows="4"
          fz-elastic
          [(ngModel)]="reason">
      </textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline btn-confirm"
        [style.--c]="themeList?.header_table_color"
        [style.--bg]="'#fff'"
        (click)="closeModal()">
      {{"MEMOS.CANCEL" | translate}}
    </button>
    <button *ngIf="typeCheckDetail === 'individual' && individual"
        class="btn btn-confirm"
        appSetTheme
        type="button"
        [disabled]="!reason"
        (click)="rejectIndividual(individual.id)">
      {{"MEMOS.CONFIRM" | translate}}
    </button>
    <button *ngIf="typeCheckDetail === 'company' && company"
        class="btn btn-confirm"
        appSetTheme
        type="button"
        [disabled]="!reason"
        (click)="rejectCompany(company.id)">
      {{"MEMOS.CONFIRM" | translate}}
    </button>
  </div>
</ng-template>
