<div>
    <div class="row">
      <div class="col-lg-4 custom-box"
      #allPage
        [ngClass]="switchPage? 'display-sm-none': ''"
        (resized)="onUserListPanelResize($event)">
        <div class="content-frame d-flex flex-column">
          <div class="row"
          #headerPage
            (clickOutside)="closeToggle($event)">
            <div class="col-lg-5 col-6">
              <h3 class="">{{'MANAGE-CUSTOMER.CUSTOMER' | translate}}</h3>
              <a *ngIf="permission.checkAccess(['Viewer'])" class="text-info">{{count}} {{"CREATE-USER.MEMBERS" |
                translate}}</a>
            </div>
            <div *ngIf="!permission.checkAccess(['Viewer'])" class="col-lg-7 col-6 text-right">
              <button class="btn p-btn"
                  type="button"
                  appSetTheme
                  (click)="openModalAdd(modalAddCustomer)">
                <i class="fas fa-plus"></i> {{"MANAGE-CUSTOMER.ADD-CUSTOMER"|translate}}
              </button>
            </div>
            <div class="col text-left">
              <a *ngIf="!permission.checkAccess(['General', 'Viewer'])" class="text-info">{{count}}
                {{"CREATE-USER.MEMBERS" | translate}}</a>
            </div>
            <div class="selections-tab-container w-100 mb-3">
              <div class="tab w-100">
                <ng-container *ngFor="let item of selectionTabItemsType">
                  <span class="item"
                      [class.active]="activeSelectionTabItemType === item"
                      (click)="confirmSwitchTab(item)"
                      [routerLink]="['/', 'customer-user']">
                    <span>{{(item.translateLabel|translate) || item.name}}</span>
                  </span>
                </ng-container>
                <span class="item w-100">&nbsp;</span>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex flex-column text-left mb-3">
                <label class="text-muted">{{ 'DASHBOARD.SEARCH' | translate }}</label>
                <input class="form-control"
                    type="text"
                    placeholder="{{ 'DASHBOARD.SEARCH' | translate }}"
                    [(ngModel)]="searchValue"
                    (keyup)="searchInput$.next($event.target.value)">
                <div>
                  <div class="span-calendar d-inline"
                    (click)="showToggle=!showToggle">
                  <svg-icon src="assets/images/icons/filter.svg"
                      [svgStyle]="{ 'fill': showToggle ? '#9b9b9b' : '#000'}"></svg-icon>
                </div>
                </div>
                <div class="position-relative">
                <div *ngIf="showToggle"
                    class="width-box py-4">
                  <div class="form-group mb-4">
                    <label>{{'MANAGE-CUSTOMER.STATUS' | translate}}</label>
                    <div class="form-group">
                      <ng-select
                          [items]="statusForSearch"
                          class="custom"
                          [bindLabel]="'label'"
                          bindValue="value"
                          [notFoundText]="'SELECT.No items found'|translate"
                          placeholder="{{'MEMOS.SELECT' | translate}}"
                          [(ngModel)]="search.status"
                          name="status"
                          >
                      </ng-select>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <label>{{'MANAGE-CUSTOMER.TYPE-PROPERTY' | translate}}</label>
                    <div class="form-group">
                      <ng-select [items]="propertiesList" *ngIf="!loadingDropdown"
                          class="custom"
                          [bindLabel]="translate.currentLang === 'en' ? 'context.name_en': 'context.name_th'"
                          bindValue="value"
                          [notFoundText]="'SELECT.No items found'|translate"
                          placeholder="{{'MEMOS.SELECT' | translate}}"
                          [(ngModel)]="search.property"
                          name="property">
                      </ng-select>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <div class="text-right">
                      <div class="btn p-btn mx-1 btn-clear btn-outline"
                          [style.--c]="themeList?.header_table_color"
                          (click)="clearSearch()">
                        {{'LOG.CLEAR'| translate}}
                      </div>
                      <div class="btn p-btn mx-1"
                          appSetTheme
                          type="button"
                          (click)="onFilterChange()">
                        {{'DASHBOARD.SEARCH'| translate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>

          <div class="scrollable-box">
            <div class="user"
                *ngFor="let list of userList">
                <div class="pointer d-flex justify-content-between"
                    [routerLink]="['/', 'customer-user']"
                    [queryParams]="{id: list.id, type: list.company_type?'company':'individual', person: list.person_id}"
                    (click)="switchToUserDetailForMobile(true)"
                    routerLinkActive="active-select-box">
                    <div class="p-2">
                      <p class="user-name m-0 detail">{{ list.first_name || '' }}
                          {{ list.last_name || '' }}</p>
                      <p class="user-name m-0">{{ list.business_name || '' }}</p>
                      <div *ngIf="list.company_type">
                        <p *ngIf="list.company_type ===  'limited_company'"
                          class="label-sm">
                          {{"MANAGE-CUSTOMER.COMPANY" | translate}}
                        </p>
                        <p *ngIf="list.company_type ===  'public_limited_company'"
                            class="label-sm">
                          {{"MANAGE-CUSTOMER.LIMITED-COMPANY" | translate}}
                        </p>
                        <p *ngIf="list.company_type ===  'limited_partnership'"
                            class="label-sm">
                          {{"MANAGE-CUSTOMER.PARTNERSHIP" | translate}}
                        </p>
                        <p *ngIf="list.other_company_type"
                            class="label-sm">
                          {{ list.other_company_type }}
                        </p>
                      </div>
                      <div *ngIf="!list.company_type">
                        <p class="label-sm">{{ list.job !== 'other' ? translate.currentLang === 'en'
                          ? translateJob(list.job)?.label_en : translateJob(list.job)?.label : list.job_other }}</p>
                      </div>
                    </div>
                    <div class="p-2 d-flex justify-content-center align-items-center">
                      <div *ngIf="list.status_for_approval_by_admin === 'pending'"
                        class="status-pending">
                        <p class="item-custom m-0">{{'APPROVAL.PENDING'|translate}}</p>
                      </div>
                      <div *ngIf="list.status_for_approval_by_admin === 'reject'"
                        class="status-reject">
                        <p class="item-custom m-0">{{'APPROVAL.NOT-APPROVED'|translate}}</p>
                      </div>
                    </div>
                </div>
                <div *ngIf="!userList"
                    class="text-center m-t-20">
                  <span class="text-white">{{"CREATE-USER.NO-MEMBER" | translate}}</span>
                </div>
            </div>
          </div>
          <div *ngIf="count !== 0">
            <div class="text-color text-underline text-right pointer pt-3"
                 *ngIf="permission.checkAccess(['Admin','Master Admin']) || currentUserCandDwnloadCustomerCsv"
                [style.--c]="themeList?.header_table_color"
                (click)='downloadExcel()'>
              <svg-icon src="assets/images/icon_download2.svg"
                  class="icon"
                  [svgStyle]="{'width.px': 15, 'height.px': 15}"></svg-icon>
              {{"CREATE-USER.DOWNLOAD-CUSTOMER-REPORT" | translate}}
            </div>
            <hr>
            <div class="d-flex justify-content-end mt-3">
              <app-pagination class="w-100"
                  [boundaryLinks]="true"
                  [count]="count"
                  [ellipses]="false"
                  [maxSize]="((userListPanelWidth - 270) / 38) > 5
                    ? 5
                    : ((userListPanelWidth - 270) / 38) | number: '1.0-0'"
                  [noneSize]="true"
                  [newDesign]="true"
                  [page]="currentPage"
                  [rotate]="true"
                  [(pageSize)]="pageSize"
                  (pageChange)="changePage($event)">
              </app-pagination>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8"
          [ngClass]="switchPage? '': 'display-sm-none'">
        <div class="content-frame">
          <app-customer-detail
            (closePage)="switchToUserDetailForMobile($event)"
            (saveDelete)="refreshList()"
            (enableUser)="refreshList()">
          </app-customer-detail>
        </div>
      </div>
    </div>

    <ng-template #modalAddCustomer
        let-c="close"
        let-d="dismiss">
      <div class="modal-header border-bottom-0 pt-0 pb-0">
        <h4 class="px-4 pt-4"
          *ngIf="tabType === 'individual'">
          {{"MANAGE-CUSTOMER.ADD-CUSTOMER"|translate}} ({{"MANAGE-CUSTOMER.INDIVIDUAL"|translate}})
        </h4>
        <h4 class="px-4 pt-4"
          *ngIf="tabType === 'company'">
          {{"MANAGE-CUSTOMER.ADD-CUSTOMER"|translate}} ({{"MANAGE-CUSTOMER.COMPANY"|translate}})
        </h4>
      </div>
      <div class="modal-body custom-box p-4">
        <div class="p-0">
          <app-customer-add
            [(formGroup)]="formGroup"
            [submitted]="submitted"
            [typeItem]="tabType"
            (clearError)="clearError($event)">
          </app-customer-add>
        </div>
        <div *ngIf="errorDetail">
          <div class="modal-body row p-0">
            <div class="col-12 mt-4">
              <div class="row">
                <div class="col-12">
                  <ngb-progressbar [type]="'danger'"
                      [value]="100"
                      height="2rem">
                    <div class="font-weight-bold">
                      <h4 class="mb-0">Failure</h4>
                    </div>
                  </ngb-progressbar>
                </div>
                <div class="col-12 mt-4">
                  <ngb-alert type="danger"
                      [dismissible]="false">
                    <h5 class="text-danger">{{ 'REQUEST.ERROR-OCCURRED' | translate | titlecase }}</h5>
                    <div class="frame error p-3">
                      <ul>
                        <li *ngFor="let detail of errorDetail;">{{detail}}</li>
                      </ul>
                    </div>
                  </ngb-alert>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer ">
        <button type="button"
            [style.--c]="themeList?.header_table_color"
            class="btn btn-outline"
            (click)="closeModalAdd()">{{"CREATE-USER.CANCEL"|translate}}
        </button>
        <button type="button"
            class="btn"
            [ladda]="loadSave"
            (click)="saveUser(openOtp)"
            appSetTheme>{{"CREATE-USER.ADD"|translate}}
        </button>
      </div>
    </ng-template>
</div>

<!-- otp for customer -->
<ng-template #openOtp
             let-c="close"
             let-d="dismiss">
  <div class="modal-header otp-line">
    <h5 class="modal-title">
      {{'LANDING.OTP-VERIFY' | translate}}
    </h5>
    <button type="button"
            class="close"
            aria-label="Close"
            (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <div class="form-group">
      <div>
        <h6>
          {{'LANDING.OTP-ENTER' | translate}}
          ({{'LANDING.REF' | translate}}: {{refDisPlay}})
        </h6>
        <div class="countdown"
             [class.text-danger]="timeLeft === 0">
          {{'LANDING.OTP-REMAINING' | translate}} {{ timeLeft * 1000 | date:'mm:ss' }} {{'LANDING.OTP-MINUTE' | translate}}
        </div>
        <div class="mt-1 custom-box">
          <input class="f-20 col-8 "
                 type="text"
                 maxlength="6"
                 (keypress)="numberOnly($event)"
                 [(ngModel)]="otp">
        </div>
      </div>
    </div>
    <div class="text-center mt-3">
      <button class="btn btn-success"
              [ladda]="isLoadingOTP"
              (click)="verifyOTP()">{{'LANDING.OTP-CONFIRM' | translate}}</button>
      <div class="mt-4">
        <a class="text-muted border-bottom pointer"
           (click)="sendOTP()">{{'LANDING.OTP-RESEND' | translate}}</a>
      </div>
    </div>
  </div>
</ng-template>

<app-export-excel-modal #exportExcelModal
  [title]="'EXPORT.CUSTOMER-REPORT'">
</app-export-excel-modal>
