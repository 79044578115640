import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'suffix',
})
export class SuffixPipe implements PipeTransform {
  transform(value: unknown, mark: string): unknown {
    if (!value) {
      return null;
    }
    return `${value}${mark}`;
  }
}
