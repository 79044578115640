import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  LangChangeEvent,
  TranslateService,
} from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { AlertService } from 'src/app/core/services/alert.service';
import {
  DropdownResponse,
  DropdownService,
} from 'src/app/shared/service/dropdown.service';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
} from 'rxjs/operators';
import { ResizedEvent } from 'angular-resize-event';
import { CustomerUserService } from '../../services/customer-user.service';
import {
  CompanyList,
  IndividualList,
} from '../../model/customer-user.model';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import {
  OtpSend,
  OtpVerify,
} from 'src/app/modules/customer-page/shared/customer-page.models';
import {
  JOB_LIST,
  NUMBEROFRESIDENT_LIST,
} from '../../../customer-page/shared/customer-page';
import { CustomerPageService } from '../../../customer-page/shared/customer-page.service';
import { PermissionService } from '../../../../core/services/permission.service';
import { ExportExcelModalComponent } from 'src/app/shared/components/export-excel-modal/export-excel-modal.component';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';

type TabName = {
  name: string;
  hasNotification: boolean;
  translateLabel: string;
  buttonLabel: string;
};

@Component({
  selector: 'app-customer-user',
  templateUrl: './customer-user.component.html',
  styleUrls: ['./customer-user.component.scss'],
})
export class CustomerUserComponent implements OnInit, OnDestroy {
  count = 0;
  currentPage = 1;
  pageSize = 40;
  userList = [];
  formGroup: FormGroup;
  userListPanelWidth: number;
  subscription: Subscription;
  searchValue;
  submitted: boolean;
  modal: NgbModalRef;
  confirmSwitchTabModal: NgbModalRef;
  pendingActionSelectionTabItem: any;
  loadSave;
  selectionTabItems: TabName[] = [
    {
      name: 'Add User',
      hasNotification: false,
      translateLabel: 'CREATE-USER.ADD-NEW-USER',
      buttonLabel: 'CREATE-USER.ADD',
    },
  ];
  activeSelectionTabItem: TabName = this.selectionTabItems[0];
  selectionTabItemsType: TabName[] = [
    {
      name: 'individual',
      hasNotification: true,
      translateLabel: 'MANAGE-CUSTOMER.INDIVIDUAL',
      buttonLabel: 'MANAGE-CUSTOMER.INDIVIDUAL',
    },
    {
      name: 'company',
      hasNotification: true,
      translateLabel: 'MANAGE-CUSTOMER.COMPANY',
      buttonLabel: 'MANAGE-CUSTOMER.COMPANY',
    },
  ];
  activeSelectionTabItemType: TabName = this.selectionTabItemsType[0];
  tabType: string;
  switchPage = false;
  errorDetail: any;
  searchText: string;
  searchInput$ = new Subject<string>();
  themeList: ThemeList;
  showToggle = false;
  // otp
  isLoadingOTP = false;
  timeLeft = 300;
  interval;
  otp = null;
  ref: string;
  refDisPlay: string;
  phoneNumber: string;
  jobList = JOB_LIST;
  NumberOfOccupantList = NUMBEROFRESIDENT_LIST;
  statusForSearch = [
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Reject',
      value: 'reject',
    },
    {
      label: 'Approve',
      value: 'approve',
    },
  ];
  propertiesList = [];

  search = {
    status: null,
    property: null,
  };
  loadingDropdown = false;
  allPageHeight: number;
  headerPageHeight: number;
  isSearch = false;
  searchHeight: number;
  @ViewChild('allPage', { static: false })
  allPage: ElementRef;
  @ViewChild('headerPage', { static: false })
  headerPage: ElementRef;
  @ViewChild('searchList', { static: false })
  searchList: ElementRef;
  @ViewChild('exportExcelModal')
  exportExcelModal: ExportExcelModalComponent;

  currentUserCandDwnloadCustomerCsv = false;
  constructor(
    private dropdownService: DropdownService,
    private builder: FormBuilder,
    private modalService: NgbModal,
    public translate: TranslateService,
    private alert: AlertService,
    private themeService: ThemeService,
    private customerService: CustomerUserService,
    private spinner: SpinnerService,
    public permission: PermissionService,
    private customSmsService: CustomerPageService,
    private activateRoute: ActivatedRoute,
    private store: Store,
  ) {
    this.formGroup = this.builder.group({});
    this.translate.use(localStorage.getItem('lang'));
    this.searchInput$
      .pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe((searchText: string) => {
        this.currentPage = 1;
        this.searchValue = searchText;
        this.changePage();
      });
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  ngOnInit(): void {
    const { can_download_customer_csv } = this.store.selectSnapshot(
      (s) => s.auth,
    );
    this.currentUserCandDwnloadCustomerCsv =
      can_download_customer_csv ?? false;
    this.activeSelectionTabItem = this.selectionTabItems[0];
    this.tabType = this.selectionTabItemsType[0].name;
    this.userListPanelWidth = window.innerWidth;
    this.changePage();
    this.getProperties();
    this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.translateDropdown();
      },
    );
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  getProperties() {
    const params = { type: 'property' };
    this.dropdownService
      .getDropdownPublic(params)
      .subscribe((dropdown: DropdownResponse) => {
        this.propertiesList = dropdown.property;
      });
  }

  clearSearch() {
    this.searchValue = '';
    this.search.status = null;
    this.search.property = null;
    this.changePage();
  }

  onFilterChange(): void {
    this.currentPage = 1;
    this.showToggle = false;
    this.changePage();
  }

  changePage(page?) {
    let list = [];
    this.userList = [];
    this.spinner.show();
    if (this.activeSelectionTabItemType.name === 'individual') {
      this.currentPage = page ? page : 1;
      const params = {
        page: this.currentPage,
        search: this.searchValue || '',
        status_for_approval_by_admin: this.search.status || '',
        property: this.search.property || '',
      };
      this.customerService
        .getItemIndividual(params)
        .pipe(
          finalize(() => {
            this.spinner.hide();
          }),
        )
        .subscribe((res: IndividualList) => {
          list = res.results;
          this.userList = list;
          this.count = res.count;
          this.searchHeight =
            this.searchList?.nativeElement.scrollHeight;
        });
    } else {
      this.currentPage = page ? page : 1;
      const params = {
        page: this.currentPage,
        search: this.searchValue || '',
        status_for_approval_by_admin: this.search.status || '',
        property: this.search.property || '',
      };
      this.customerService
        .getItemCompany(params)
        .pipe(
          finalize(() => {
            this.spinner.hide();
          }),
        )
        .subscribe((res: CompanyList) => {
          list = res.results;
          this.userList = list;
          this.count = res.count;
          this.searchHeight =
            this.searchList?.nativeElement.scrollHeight;
        });
    }
  }

  onUserListPanelResize(event: ResizedEvent): void {
    this.userListPanelWidth = event.newWidth;
    this.allPageHeight = this.allPage?.nativeElement.scrollHeight;
    this.headerPageHeight =
      this.headerPage?.nativeElement.scrollHeight;
  }

  openModalAdd(content) {
    this.errorDetail = null;
    this.submitted = false;
    this.modal = this.modalService.open(content, {
      size: 'lg',
      backdrop: 'static',
      centered: true,
    });
  }

  closeModalAdd(): void {
    this.loadSave = false;
    this.modalService.dismissAll();
  }

  saveUser(modal?) {
    const data = this.prepareData();
    if (!data) {
      return;
    }
    this.submitted = false;
    return this.formGroup.value.type_user === 'individual'
      ? this.checkInitData(modal)
      : this.addCompany();
  }

  translateDropdown(): void {
    this.loadingDropdown = true;
    setTimeout(() => {
      this.loadingDropdown = false;
    }, 100);
  }

  handleError(response, separator) {
    this.loadSave = false;
    if (response.error) {
      if (response.error.detail) {
        this.errorDetail = response.error.detail;
      } else {
        const errorText: string = response.error.join(separator);
        this.alert.error(errorText);
      }
    } else {
      this.alert.error();
    }
  }

  addIndividual() {
    const data = this.prepareData();
    if (!data) {
      return;
    }
    this.customerService.addIndividual(data).subscribe(
      () => {
        this.handleSuccess();
      },
      (er) => {
        this.alert.error(er.error.non_field_errors[0]);
        this.loadSave = false;
      },
    );
  }

  addCompany() {
    const data = this.prepareData();
    if (!data) {
      return;
    }
    this.customerService.addCompany(data).subscribe(
      () => {
        this.handleSuccess();
      },
      (err) => {
        this.alert.error(
          err?.error?.committees || err.error.non_field_errors,
        );
        this.loadSave = false;
      },
    );
  }

  handleSuccess() {
    this.loadSave = false;
    this.modalService.dismissAll();
    this.changePage();
    this.alert.success(this.translate.instant('MEMOS.SAVE-CHANGES'));
  }

  prepareData() {
    this.loadSave = true;
    this.submitted = true;
    if (this.formGroup.invalid) {
      this.loadSave = false;
      return false;
    }

    if (this.formGroup.value.type_user === 'individual') {
      return {
        card_type: this.formGroup.getRawValue().card_type || '',
        card_no:
          this.formGroup.getRawValue().card_type === 'citizen_id'
            ? this.formGroup.getRawValue().card_no.replace(/\D/g, '')
            : this.formGroup.getRawValue().card_no,
        name_prefix: this.formGroup.getRawValue().name_prefix || '',
        other_prefix: this.formGroup.getRawValue().other_prefix || '',
        first_name: this.formGroup.getRawValue().first_name || '',
        last_name: this.formGroup.getRawValue().last_name || '',
        birth_date: this.formGroup.getRawValue().birth_date || '',
        job: this.formGroup.getRawValue().job || '',
        job_other: this.formGroup.getRawValue().job_other || '',
        line_id: this.formGroup.getRawValue().line_id || '',
        house_no: this.formGroup.getRawValue().house_no || '',
        village_no: this.formGroup.getRawValue().village_no || '',
        road: this.formGroup.getRawValue().road || '',
        province: this.formGroup.getRawValue().province || '',
        district: this.formGroup.getRawValue().district || '',
        sub_district: this.formGroup.getRawValue().sub_district || '',
        postal_code: this.formGroup.getRawValue().postal_code || '',
        contact_house_no:
          this.formGroup.getRawValue().contact_house_no || '',
        contact_village_no:
          this.formGroup.getRawValue().contact_village_no || '',
        contact_road: this.formGroup.getRawValue().contact_road || '',
        contact_province:
          this.formGroup.getRawValue().contact_province || '',
        contact_district:
          this.formGroup.getRawValue().contact_district || '',
        contact_sub_district:
          this.formGroup.getRawValue().contact_sub_district || '',
        contact_postal_code:
          this.formGroup.getRawValue().contact_postal_code || '',
        email: this.formGroup.getRawValue().email || '',
        secondary_email:
          this.formGroup.getRawValue().secondary_email || '',
        phone_number: this.formGroup.getRawValue().phone_number
          ? this.formGroup
              .getRawValue()
              .phone_number.replace(/\D/g, '')
          : '',
        secondary_phone_number: this.formGroup.getRawValue()
          .secondary_phone_number
          ? this.formGroup
              .getRawValue()
              .secondary_phone_number.replace(/\D/g, '')
          : '',
        property: this.formGroup.getRawValue().property || '',
        location: this.formGroup.getRawValue().location || '',
        building: this.formGroup.getRawValue().building || '',
        room_reserve: this.formGroup.getRawValue().room_reserve,
        duration_contract:
          this.formGroup.getRawValue().duration_contract,
        additional_details:
          this.formGroup.getRawValue().additional_details || '',
        number_of_occupants:
          this.formGroup.getRawValue().number_of_occupants,
      };
    } else {
      return {
        registration_id: this.formGroup.getRawValue().registration_id
          ? this.formGroup
              .getRawValue()
              .registration_id.replace(/\D/g, '')
          : '',
        company_type: this.formGroup.getRawValue().company_type || '',
        other_company_type:
          this.formGroup.getRawValue().other_company_type || '',
        business_name:
          this.formGroup.getRawValue().business_name || '',
        committees: this.formGroup
          .getRawValue()
          .committees.filter((committee) => {
            return !Object.values(committee).every((val) => {
              return val === null || val === '';
            });
          })
          .map((committee) => {
            return {
              id: null,
              prefix: committee.prefix || '',
              prefix_other: committee.prefix_other || '',
              first_name: committee.first_name || '',
              last_name: committee.last_name || '',
              email: committee.email || '',
              phone_number: committee.phone_number
                ? committee.phone_number.replace(/\D/g, '')
                : '',
            };
          }),
        contact_prefix:
          this.formGroup.getRawValue().contact_prefix || '',
        contact_prefix_other:
          this.formGroup.getRawValue().contact_prefix_other || '',
        contact_first_name:
          this.formGroup.getRawValue().contact_first_name || '',
        contact_last_name:
          this.formGroup.getRawValue().contact_last_name || '',
        contact_email:
          this.formGroup.getRawValue().contact_email || '',
        contact_phone_number: this.formGroup.getRawValue()
          .contact_phone_number
          ? this.formGroup
              .getRawValue()
              .contact_phone_number.replace(/\D/g, '')
          : '',
        house_no: this.formGroup.getRawValue().house_no || '',
        village_no: this.formGroup.getRawValue().village_no || '',
        road: this.formGroup.getRawValue().road || '',
        province: this.formGroup.getRawValue().province || '',
        district: this.formGroup.getRawValue().district || '',
        sub_district: this.formGroup.getRawValue().sub_district || '',
        postal_code: this.formGroup.getRawValue().postal_code || '',
        contact_house_no:
          this.formGroup.getRawValue().contact_house_no || '',
        contact_village_no:
          this.formGroup.getRawValue().contact_village_no || '',
        contact_road: this.formGroup.getRawValue().contact_road || '',
        contact_province:
          this.formGroup.getRawValue().contact_province || '',
        contact_district:
          this.formGroup.getRawValue().contact_district || '',
        contact_sub_district:
          this.formGroup.getRawValue().contact_sub_district || '',
        contact_postal_code:
          this.formGroup.getRawValue().contact_postal_code || '',
        email: this.formGroup.getRawValue().email || '',
        secondary_email:
          this.formGroup.getRawValue().secondary_email || '',
        phone_number: this.formGroup.getRawValue().phone_number
          ? this.formGroup
              .getRawValue()
              .phone_number.replace(/\D/g, '')
          : '',
        secondary_phone_number: this.formGroup.getRawValue()
          .secondary_phone_number
          ? this.formGroup
              .getRawValue()
              .secondary_phone_number.replace(/\D/g, '')
          : '',
        property: this.formGroup.getRawValue().property || '',
        location: this.formGroup.getRawValue().location || '',
        building: this.formGroup.getRawValue().building || '',
        room_reserve: this.formGroup.getRawValue().room_reserve,
        duration_contract:
          this.formGroup.getRawValue().duration_contract,
        additional_details:
          this.formGroup.getRawValue().additional_details || '',
        number_of_occupants:
          this.formGroup.getRawValue().number_of_occupants,
      };
    }
  }

  switchToUserDetailForMobile(value: boolean): void {
    this.switchPage = value;
  }

  clearError(event): void {
    this.errorDetail = event;
  }

  confirmSwitchTab(event) {
    this.showToggle = false;
    this.tabType = event.name;
    this.activeSelectionTabItemType = event;
    this.clearSearch();
  }

  refreshList(): void {
    this.activateRoute.queryParams.subscribe((res) => {
      if (res.type === 'individual') {
        this.activeSelectionTabItem = this.selectionTabItems[0];
      } else {
        this.activeSelectionTabItem = this.selectionTabItems[1];
      }
    });
    this.changePage();
  }

  openModal(event): void {
    this.modal = this.modalService.open(event, {
      backdrop: 'static',
      size: 'md',
      keyboard: false,
      centered: true,
    });
    this.startTimer();
  }

  onOtpChange(event): void {
    if (event.length === 6) {
      this.otp = event;
    }
  }

  startTimer(): void {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.pauseTimer();
      }
    }, 1000);
  }

  pauseTimer(): void {
    clearInterval(this.interval);
  }

  closeModal(): void {
    this.pauseTimer();
    this.timeLeft = 300;
    this.modal.close();
    this.loadSave = false;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  verifyOTP() {
    const data = {
      otp_code: this.otp,
      ref: this.ref,
      phone_number: this.formGroup.value.phone_number.replace(
        /-/g,
        '',
      ),
      register_id: this.formGroup.value.card_no,
      register_type: 'individual_person',
      action_from: 'Verify Customer Page',
    };
    this.isLoadingOTP = true;
    this.customSmsService.verifyOtpSms(data).subscribe(
      (res: OtpVerify) => {
        this.isLoadingOTP = false;
        this.loadSave = false;
        this.modal.close();
        this.alert.success('Verify OTP success');
        this.addIndividual();
      },
      () => {
        this.isLoadingOTP = false;
        this.loadSave = false;
        this.modal.close();
        this.alert.error('OTP is invalid. Please check again');
      },
    );
  }

  closeOTP() {
    const data = {
      ref: this.ref,
      otp_code: this.otp,
      card_no: this.formGroup.get('card_no').value.replace(/-/g, ''),
      phone_number: this.formGroup
        .get('phone_number')
        .value.replace(/-/g, ''),
      action_from: 'Verify Confirm Register Page',
    };
    this.customSmsService.verifyOtpSms(data).subscribe(
      (res: OtpVerify) => {
        this.modal.close();
        this.alert.success('Close OTP success');
      },
      () => {
        this.alert.error('OTP is invalid. Please check again');
      },
    );
  }

  sendOTP(modal = null) {
    this.otp = null;
    const data = {
      phone_number: this.formGroup.value.phone_number.replace(
        /-/g,
        '',
      ),
      registerid: this.formGroup.value.card_no,
      register_type: 'individual_person',
      action_from: 'Send Customer page',
    };
    this.customSmsService.sendOtpSms(data).subscribe(
      (res: OtpSend) => {
        this.ref = res.ref;
        this.refDisPlay = res.ref_display;
        this.pauseTimer();
        this.timeLeft = 300;
        this.startTimer();
      },
      (error) => {
        if (error.status === 400 && error.error) {
          const firstKey = Object.keys(error.error)[0];
          try {
            const errorObj = JSON.parse(error.error[firstKey]);
            if ('detail' in errorObj) {
              this.alert.error(errorObj.detail);
            } else {
              this.alert.error(
                `You've exceeded the maximum number of OTP requests allowed per minute. Please try again in 1 minute.`,
              );
            }
          } catch (e) {
            this.alert.error(error.error[firstKey]);
          }
        } else {
          this.alert.error(error.status);
        }
        this.closeModal();
      },
    );
    if (modal) {
      this.openModal(modal);
    }
  }

  translateJob(job) {
    return this.jobList.find((find) => {
      return job === find.value;
    });
  }

  checkInitData(modal?): void {
    if (this.formGroup.value.type_user === 'individual') {
      const params = {
        customer_card_id:
          this.formGroup.value.card_type === 'citizen_id'
            ? this.formGroup.value.card_no.replace(/\D/g, '')
            : this.formGroup.value.card_no.replace(/\D/g, ''),
        customer_email: this.formGroup.get('email').value,
        customer_type: 'individual_person',
      };
      this.customerService
        .getCheckIndividualData(params)
        .subscribe((res: any) => {
          if (res.status === '') {
            this.sendOTP(modal);
          } else {
            this.loadSave = false;
            this.alert.error(
              this.translate.instant('MANAGE-CUSTOMER.NUMBER') +
                ' ' +
                this.formGroup.value.card_no +
                ' ' +
                this.translate.instant('MANAGE-CUSTOMER.REPEAT'),
            );
          }
        });
    } else {
      const params = {
        customer_card_id: this.formGroup.value.registration_id,
        customer_email: this.formGroup.value.email,
        customer_type: 'company',
      };
      this.customerService
        .getCheckCompanyData(params)
        .subscribe((res: any) => {
          if (res.status === '') {
            this.sendOTP(modal);
          } else {
            this.loadSave = false;
            this.alert.error(
              this.translate.instant('MANAGE-CUSTOMER.NUMBER') +
                ' ' +
                this.formGroup.value.registration_id +
                ' ' +
                this.translate.instant('MANAGE-CUSTOMER.REPEAT'),
            );
          }
        });
    }
  }

  calculateHeight(height) {
    const removeMargin = 16;
    const removePadding = 32;
    const totoal_scrol_lable_box =
      this.allPageHeight -
      removePadding -
      removeMargin -
      (this.headerPageHeight + 100);
    if (!this.searchValue) {
      return totoal_scrol_lable_box;
    } else {
      return totoal_scrol_lable_box - height;
    }
  }

  closeToggle(event?): void {
    if (event) {
      const target = event.target as HTMLElement;
      const classList = target.classList;
      //classList mean target class when click
      if (
        classList.contains('ng-option') ||
        classList.contains('ng-option-label') ||
        target.tagName === 'BODY' ||
        classList.contains('btn-remove-item') ||
        classList.contains('fa-times')
      ) {
        return;
      }
      this.showToggle = false;
    }
  }

  downloadExcel() {
    this.customerService.downloadCustomerReport().subscribe(
      (res: any) => {
        const progressTaskId = res.task_id;
        this.exportExcelModal.showModal(progressTaskId);
      },
      (err) => {
        this.alert.error(
          'System error, Please contact technical support.',
        );
      },
    );
  }
}
