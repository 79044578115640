import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { HomeComponent } from './core/components/home/home.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { LoginComponent } from './core/components/login/login.component';
import { AdfsCallbackComponent } from './core/components/adfs-callback/adfs-callback.component';
import { MaintenanceComponent } from './core/components/maintenance/maintenance.component';
import { Component } from '@angular/core';
import { PermissionNotFoundComponent } from './shared/components/permission-not-found/permission-not-found.component';

@Component({
  selector: 'app-media-window-component',
  template: 'file is in download folder',
  styleUrls: [],
})
class MediaWindowComponent {}
const routes: Routes = [
  {
    canActivate: [AuthGuard],
    data: ['General', 'IT', 'Master Admin', 'Admin', 'Viewer'],
    path: '',
    component: HomeComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'adfs-callback',
    component: AdfsCallbackComponent,
  },
  {
    path: 'window-media',
    component: MediaWindowComponent,
  },
  {
    path: 'profile-detail',
    data: ['General', 'IT', 'Master Admin', 'Admin', 'Viewer'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/profile/profile.module').then(
        (m) => m.ProfileModule,
      ),
  },
  {
    path: 'news-feed',
    data: ['General', 'IT', 'Master Admin', 'Admin', 'Viewer'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/new-feed/new-feed.module').then(
        (m) => m.NewFeedModule,
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('../app/modules/register/register.module').then(
        (m) => m.RegisterModule,
      ),
  },
  {
    path: 'configuration',
    data: ['Master Admin', 'Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/setting/setting.module').then(
        (m) => m.SettingModule,
      ),
  },
  {
    path: 'users',
    data: ['Master Admin', 'Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/users/users.module').then(
        (m) => m.UsersModule,
      ),
  },
  {
    path: 'customer-user',
    data: ['Master Admin', 'Admin', 'IT', 'General', 'Viewer'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/customer-user/customer-user.module'
      ).then((m) => m.CustomerUserModule),
  },
  {
    path: 'department',
    data: ['Master Admin', 'Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/department/department.module').then(
        (m) => m.DepartmentModule,
      ),
  },
  {
    path: 'manage-types-upload-memo',
    data: ['Master Admin', 'Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/manage-types-upload-memo/manage-types-upload-memo.module'
      ).then((m) => m.ManageTypesUploadMemoModule),
  },
  {
    path: 'master-data',
    data: ['Master Admin', 'Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/manage-master-data/manage-master-data.module'
      ).then((m) => m.ManageMasterDataModule),
  },
  {
    path: 'manage-csr',
    data: ['Master Admin', 'Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/manage-csr/manage-csr.module').then(
        (m) => m.ManageCsrModule,
      ),
  },
  {
    path: 'kpi-dashboard',
    data: ['Master Admin', 'Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
  },
  {
    path: 'usage-dashboard',
    data: ['Admin', 'Master Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/usage/usage.module').then(
        (m) => m.UsageModule,
      ),
  },
  {
    path: 'memo-report',
    data: ['Admin', 'Master Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/memo-report/memo-report.module').then(
        (m) => m.MemoReportModule,
      ),
  },
  {
    path: 'operation-log',
    data: ['Admin', 'Master Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/operation-log/operation-log.module'
      ).then((m) => m.OperationLogModule),
  },
  {
    path: 'pending-approval',
    data: ['General', 'Viewer'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/executive/executive.module').then(
        (m) => m.ExecutiveModule,
      ),
  },
  {
    path: 'approval',
    loadChildren: () =>
      import('../app/modules/approval/approval.module').then(
        (m) => m.ApprovalModule,
      ),
  },
  {
    path: 'loa',
    data: ['Master Admin', 'Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/loa/loa.module').then(
        (m) => m.LoaModule,
      ),
  },
  {
    path: 'cc-group',
    data: ['Master Admin', 'Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/cc-group/cc-group.module').then(
        (m) => m.CcGroupModule,
      ),
  },
  {
    path: 'memos',
    loadChildren: () =>
      import('./modules/memos/memo.module').then((m) => m.MemoModule),
  },
  {
    path: 'certificate',
    data: ['Master Admin', 'Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/certificate/certificate.module').then(
        (m) => m.CertificateModule,
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import(
        '../app/modules/reset-password/reset-password.module'
      ).then((m) => m.ResetPasswordModule),
  },
  {
    path: 'customer-register',
    loadChildren: () =>
      import(
        '../app/modules/customer-page/customer-page.module'
      ).then((m) => m.CustomerPageModule),
  },
  {
    path: 'dashboard-chod',
    data: ['Master Admin', 'Admin', 'IT', 'General', 'Viewer'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/dashboard-chod/dashboard-chod.module'
      ).then((m) => m.DashboardChodModule),
  },
  {
    path: 'property-dashboard',
    data: ['Master Admin', 'Admin', 'General', 'Viewer'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/property-dashboard/property-dashboard.module'
      ).then((m) => m.PropertyDashboardModule),
  },
  {
    path: 'occupancy-dashboard',
    data: ['Master Admin', 'Admin', 'General', 'Viewer'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/occupancy-dashboard/occupancy-dashboard.module'
      ).then((m) => m.OccupancyDashboardModule),
  },
  {
    path: 'request-memo',
    data: ['General', 'Viewer', 'Master Admin', 'Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/request-memo-list/request-memo-list.module'
      ).then((m) => m.RequestMemoListModule),
  },
  { path: 'website-maintenance', component: MaintenanceComponent },

  // otherwise redirect to home
  { path: 'page-not-found', component: PageNotFoundComponent },
  {
    path: 'permission-not-found',
    component: PermissionNotFoundComponent,
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
